export const properties = [
    "NO CTM",
    "NO, DENTRATE PA DENTRO",
    "ACASO YA TE PUSISTE LA VACUNA DE AMOR?",
    "MMMMM NO, EL VIRUS AUN NO SE PONE BUENA PERSONA",
    "USTED ES WN O SE HACE?",
    "NO, AFUERA HAY TORMENTA DE CACA",
    "TANTO TE IMPORTA SALIR, TANTO TE IMPORTA PERRO QL ~ Rosa",
    "NO, EL CORONAVIRUS E MALO, E MALO, E MAOMENO, E MAOMENO 🎵",
    "TANTO TE CUESTA QUEDARTE ADENTRO",
    "QUÉDATE EN TU CASA ROSA",
    "NO, QUEEEDATE, QUEEDATE EN TU CASA MIERDA, QUÉDATE EN TU CASA AWEONAO CRISTIAN",
    "EN ESTA HORAAAAAA TAN ESPECIAAL QUIERO AGRADECER Y EXPRESAR MI GRATITUUUD, " +
    "QUE TE DIJE RECIÉN?! NO PODI SALIR! QUIERO QUE TE QUEDES EN CASA A ESCUCHAR COMO SE ESCUCHA"

];