import React from "react";
import Sentence from "./Sentence";

const Home = () => (
    <div className="App">
        <header className="App-header">
            <Sentence />
        </header>
    </div>
);

export default Home
